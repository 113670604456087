
import { defineComponent, ref, createVNode } from "vue";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import useClipboard from "vue-clipboard3";
import { message, Modal } from "ant-design-vue";
import useUser from "@/use/useUser";
import AppSecretModal from "./components/AppSecretModal.vue";
import EncodingAESKeyModal from "./components/EncodingAESKeyModal.vue";
import SetUrlModel from "./components/SetUrlModel.vue";
import WhiteModal from "./components/WhiteModal.vue";
import SetWhiteModal from "./components/SetWhiteModal.vue";
import { useDetail } from "./use";

export default defineComponent({
  components: {
    EyeOutlined,
    EyeInvisibleOutlined,
    AppSecretModal,
    EncodingAESKeyModal,
    SetUrlModel,
    WhiteModal,
    SetWhiteModal,
  },
  setup() {
    const { applyInfo } = useUser();
    const labelStyle = {
      width: "120px",
      paddingLeft: "20px",
      lineHeight: "26px",
    };
    const contentStyle = { paddingLeft: "20px", lineHeight: "26px" };
    const isHide = ref<boolean>(true);
    const appSecretModalRef = ref<any>();
    const encodingAESKeyModalRef = ref<any>();
    const setUrlModelRef = ref<any>();
    const whiteModalRef = ref<any>();
    const setWhiteModalRef = ref<any>();
    const { toClipboard } = useClipboard();

    // 应用详情
    const { run: detailRun, data: applyDetialInfo } = useDetail();

    const handleChangeHide = () => {
      isHide.value = !isHide.value;
    };

    // 复制
    const handleCopy = async () => {
      await toClipboard(applyDetialInfo?.value?.appKey);
      message.success("复制成功");
    };

    // 获取重置AppSecret
    const handleConfirmAppSecret = () => {
      // 有appSecret
      const hasAppSecret = applyDetialInfo?.value?.appSecret ? true : false;
      const text = hasAppSecret ? "重置" : "获取";
      Modal.confirm({
        title: () => `你确定要${text}开发者密码(AppSecret)吗？`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          "请注意：重置AppSecret立即生效，所有使用旧AppSecret的接口将立即失效。为确保公众号的正常使用，请尽快更新AppSecret信息。",
        okText: `确定${text}`,
        onOk: () => {
          appSecretModalRef.value.open({
            applicationId: applyInfo.value?.applicationId,
            hasAppSecret: hasAppSecret,
          });
        },
      });
    };

    return {
      style: { labelStyle, contentStyle },
      isHide,
      applyDetialInfo,
      appSecretModalRef,
      encodingAESKeyModalRef,
      setUrlModelRef,
      whiteModalRef,
      setWhiteModalRef,
      handleCopy,
      handleChangeHide,
      handleConfirmAppSecret,
      detailRun,
    };
  },
});
